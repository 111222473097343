import session from '@/services/Session.js'

export default {
  getPresetVarieties() {
    return session.get('/api/preset_product')
  },
  dispensary(asocid, status) {
    if (status) {
      return session.get(
        '/api/dispensario?asocid=' + asocid + '&status=' + status
      )
    }
    return session.get('/api/dispensario?asocid=' + asocid)
  },
  getDispensaryItem(asocid, code) {
    return session.get('/api/dispensario?asocid=' + asocid + '&code=' + code)
  },
  getDispensaryItemByPid(asocid, pid) {
    return session.get('/api/dispensario?asocid=' + asocid + '&pid=' + pid)
  },
  getProductHistory(start_date, end_date, asocid, pid) {
    return session.get(
      '/api/stock_history?asocid=' +
        asocid +
        '&pid=' +
        pid +
        '&start_date=' +
        start_date +
        '&end_date=' +
        end_date
    )
  },
  getStockHistoryReport(start_date, end_date, asocid) {
    return session.get(
      '/api/stock_history?asocid=' +
        asocid +
        '&start_date=' +
        start_date +
        '&end_date=' +
        end_date
    )
  },
  createDispensaryProduct(json) {
    return session.post('/api/dispensario', json)
  },
  deleteProduct(asocid, pidsArray) {
    return session.post('/api/dispensario/delete', {
      asocid: asocid,
      pid: pidsArray
    })
  }
}
