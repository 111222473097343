<template>
  <div>
    <h2 class="mt-3 mb-2" style="text-align: left">
      {{ $t('INFORME DE RENDIMIENTO') }}
    </h2>
    <div style="display:flex">
      <b-field :label="$t('Fecha inicial')">
        <b-datetimepicker
          v-model="start_date"
          :show-week-number="false"
          :first-day-of-week="1"
          :locale="this.$i18n.locale"
          :placeholder="$t('Fecha inicial')"
          trap-focus
        >
        </b-datetimepicker>
      </b-field>
      <b-field :label="$t('Fecha final')" class="ml-4">
        <b-datetimepicker
          v-model="end_date"
          :show-week-number="false"
          :first-day-of-week="1"
          :locale="this.$i18n.locale"
          :placeholder="$t('Fecha final')"
          trap-focus
        >
        </b-datetimepicker>
      </b-field>
      <b-field
        :label="$t('Descargar')"
        class="ml-4"
        style=" position: absolute;right: .5em;top: 40px;"
      >
        <download-excel
          :data="this.filteredElements"
          :fields="json_fields"
          :header="xlsHeader()"
          name="efficiency.xls"
        >
          <b-icon icon="file-excel" size="is-medium" type="is-success"></b-icon>
        </download-excel>
        <download-excel
          :data="this.filteredElements"
          type="csv"
          :fields="json_fields"
          :header="csvHeader()"
          name="efficiency.csv"
        >
          <b-icon icon="file-csv" size="is-medium" type="is-success"></b-icon>
        </download-excel>
      </b-field>
    </div>
    <div style="display:flex">
      <b-input v-model="nameInput" :placeholder="$t('Nombre')"></b-input>
      <b-input
        class="ml-4"
        v-model="categoryInput"
        :placeholder="$t('Categoría')"
      ></b-input>
    </div>

    <b-table
      class="mb-4 mr-2"
      style="text-align: left; "
      :data="filteredElements"
      :checkable="false"
      :paginated="true"
      :per-page="perPage"
      striped
    >
      <b-table-column
        field="title"
        :label="$t('Nombre')"
        sortable
        v-slot="props"
      >
        {{ props.row.title }}
      </b-table-column>

      <b-table-column
        field="category"
        :label="$t('Categoría')"
        sortable
        v-slot="props"
      >
        {{ props.row.category }}
      </b-table-column>

      <b-table-column
        field="order"
        :label="$t('Nº de pedidos')"
        sortable
        v-slot="props"
      >
        {{ props.row.order }}
      </b-table-column>

      <b-table-column
        field="stock"
        :label="$t('Cantidad actual')"
        sortable
        v-slot="props"
        subheading="Total"
      >
        <!-- {{ props.row.stock ? props.row.stock.toFixed(2) : '0.00' }} -->
        {{ props.row.stock }}
      </b-table-column>

      <b-table-column
        field="quantity"
        :label="$t('Cantidad despachada')"
        sortable
        v-slot="props"
        :subheading="this.totalQuantity()"
      >
        {{ props.row.quantity ? props.row.quantity.toFixed(2) : '0.00' }}
      </b-table-column>

      <!-- <b-table-column
          field="cost_unitary"
          label="Costo unitario"
          sortable
          v-slot="props"
        >
          {{ props.row.cost_unitary }}
        </b-table-column> -->

      <b-table-column
        field="cost_total"
        :label="$t('Costo total')"
        sortable
        v-slot="props"
        :subheading="this.totalCostTotal()"
      >
        {{ props.row.cost_total }}
      </b-table-column>

      <b-table-column
        field="average_price"
        :label="$t('Precio medio')"
        sortable
        v-slot="props"
        :subheading="this.totalAveragePrice().toFixed(2)"
      >
        {{ props.row.average_price }}
      </b-table-column>

      <b-table-column
        field="discount"
        :label="$t('Descuento')"
        sortable
        v-slot="props"
        :subheading="this.totalDiscount().toFixed(2)"
      >
        {{ props.row.discount ? props.row.discount.toFixed(2) : '0.00' }}
      </b-table-column>

      <b-table-column
        field="amount"
        :label="$t('Ingresos brutos')"
        sortable
        v-slot="props"
        :subheading="this.totalAmount()"
      >
        {{ props.row.amount ? props.row.amount.toFixed(2) : '0.00' }}
      </b-table-column>

      <b-table-column
        field="profits"
        :label="$t('Ganancias netas')"
        sortable
        v-slot="props"
        :subheading="this.totalProfits().toFixed(2)"
      >
        {{ props.row.profits ? props.row.profits.toFixed(2) : '0.00' }}
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import APIPatients from '@/services/APIPatients.js'
import { ToolsMixin } from '@/mixins/ToolsMixin.js'
import { InitViewMixin } from '@/mixins/InitViewMixin.js'

export default {
  data() {
    return {
      json_fields: {},
      nameInput: '',
      categoryInput: '',
      elements: [],
      filteredElements: [],
      totalElements: 0,
      perPage: 15,
      page: 1,
      start_date: new Date(new Date().setDate(new Date().getDate() - 7)),
      end_date: new Date(),

      locale: 'es-ES'
    }
  },
  mixins: [ToolsMixin, InitViewMixin],
  methods: {
    xlsHeader() {
      let fi =
        this.$t('Fecha inicial') + ': ' + this.datestring(this.start_date)
      let ff = this.$t('Fecha final') + ': ' + this.datestring(this.end_date)
      return [this.$t('INFORME DE RENDIMIENTO'), fi, ff]
    },
    csvHeader() {
      let fi = this.datestring(this.start_date)
      let ff = this.datestring(this.end_date)
      return this.$t('INFORME DE RENDIMIENTO') + '   ' + fi + ' / ' + ff
    },
    setupJsonFields() {
      this.json_fields[this.$t('Nombre')] = 'title'
      this.json_fields[this.$t('Categoría')] = 'category'
      this.json_fields[this.$t('Nº de pedidos')] = 'order'
      this.json_fields[this.$t('Cantidad actual')] = 'stock'
      this.json_fields[this.$t('Cantidad despachada')] = {
        field: 'quantity',
        callback: value => {
          return Number(value).toFixed(2)
        }
      }
      this.json_fields[this.$t('Costo total')] = 'cost_total'
      this.json_fields[this.$t('Precio medio')] = {
        field: 'average_price',
        callback: value => {
          return Number(value).toFixed(2)
        }
      }
      this.json_fields[this.$t('Descuento')] = {
        field: 'discount',
        callback: value => {
          return Number(value).toFixed(2)
        }
      }
      this.json_fields[this.$t('Ingresos brutos')] = {
        field: 'amount',
        callback: value => {
          return Number(value).toFixed(2)
        }
      }
      this.json_fields[this.$t('Ganancias netas')] = {
        field: 'profits',
        callback: value => {
          return Number(value).toFixed(2)
        }
      }
    },
    totalQuantity() {
      var total = 0
      if (this.filteredElements) {
        this.filteredElements.forEach(element => {
          total = total + element.quantity
        })
      }
      return total.toFixed(2)
    },
    totalCostTotal() {
      var total = 0
      if (this.filteredElements) {
        this.filteredElements.forEach(element => {
          total = total + Number(element.cost_total)
        })
      }
      return total
    },
    totalAveragePrice() {
      var total = 0
      if (this.filteredElements) {
        this.filteredElements.forEach(element => {
          total = total + element.average_price
        })
      }
      return total
    },
    totalDiscount() {
      var total = 0
      if (this.filteredElements) {
        this.filteredElements.forEach(element => {
          total = total + element.discount
        })
      }
      return total
    },
    totalAmount() {
      var total = 0
      if (this.filteredElements) {
        this.filteredElements.forEach(element => {
          total = total + element.amount
        })
      }
      return total
    },
    totalProfits() {
      var total = 0
      if (this.filteredElements) {
        this.filteredElements.forEach(element => {
          total = total + element.profits
        })
      }
      return total
    },
    downloadData(card_id) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      this.validateAccess(['software_gestion_club'])
      this.validateRoles(['Administrador', 'Manager', 'Budtender', 'Recepción'])
      let json = {
        asocid: this.currentClub.nid,
        start_date: Date.parse(this.start_date) / 1000,
        end_date: Date.parse(this.end_date) / 1000
      }
      if (card_id) {
        json.card_id = card_id
      }
      APIPatients.reportSalesProduct(json)
        .then(response => {
          this.elements = response.data.products
          this.filteredElements = this.elements
          loadingComponent.close()
          this.setupJsonFields()
        })
        .catch(error => {
          loadingComponent.close()
        })
    }
  },
  created() {
    // this.downloadData()
  },
  watch: {
    '$route.params.ms_id': {
      handler: function(search) {
        console.log(search)
        // const loadingComponent = this.$buefy.loading.open({
        //   container: null
        // })
        this.downloadData(this.$route.query.ms_id)
      },
      deep: true,
      immediate: true
    },
    currentClub() {
      this.downloadData(this.$route.query.ms_id)
    },
    start_date() {
      this.downloadData(this.$route.query.ms_id)
    },
    end_date() {
      this.downloadData(this.$route.query.ms_id)
    },
    nameInput() {
      this.filteredElements = this.elements.filter(element => {
        return (
          element.title
            .toString()
            .toLowerCase()
            .indexOf(this.nameInput.toLowerCase()) >= 0
        )
      })
    },
    categoryInput() {
      this.filteredElements = this.elements.filter(element => {
        element.category = element.category ? element.category : ''
        return (
          element.category
            .toString()
            .toLowerCase()
            .indexOf(this.categoryInput.toLowerCase()) >= 0
        )
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
