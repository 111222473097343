<template>
  <div>
    <h2 class="mt-3 mb-2" style="text-align: left">
      {{ $t('INFORME DE PEDIDOS') }}
    </h2>
    <div style="display:flex">
      <b-field :label="$t('Fecha inicial')">
        <b-datetimepicker
          v-model="start_date"
          :show-week-number="false"
          :first-day-of-week="1"
          :locale="this.$i18n.locale"
          :placeholder="$t('Fecha inicial')"
          trap-focus
        >
        </b-datetimepicker>
      </b-field>
      <b-field :label="$t('Fecha final')" class="ml-4">
        <b-datetimepicker
          v-model="end_date"
          :show-week-number="false"
          :first-day-of-week="1"
          :locale="this.$i18n.locale"
          :placeholder="$t('Fecha final')"
          trap-focus
        >
        </b-datetimepicker>
      </b-field>
      <b-field
        :label="$t('Descargar')"
        class="ml-4"
        style=" position: absolute;right: .5em;top: 40px;"
      >
        <download-excel
          :data="this.orders"
          :fields="json_fields"
          :header="xlsHeader()"
          name="orders.xls"
        >
          <b-icon icon="file-excel" size="is-medium" type="is-success"></b-icon>
        </download-excel>
        <download-excel
          :data="this.orders"
          type="csv"
          :fields="json_fields"
          name="orders.csv"
          :header="csvHeader()"
        >
          <b-icon icon="file-csv" size="is-medium" type="is-success"></b-icon>
        </download-excel>
      </b-field>
    </div>
    <b-table
      class="my-table mr-2"
      style="text-align: left;"
      :data="this.orders"
      :sticky-header="true"
      :paginated="isPaginated"
      @page-change="onPageChange"
      :total="totalElements"
      backend-pagination
      :per-page="perPage"
      :opened-detailed="defaultOpenedDetails"
      detailed
      detail-key="order_id"
      :show-detail-icon="showDetailIcon"
      :pagination-simple="isPaginationSimple"
      :pagination-position="paginationPosition"
      :default-sort-direction="defaultSortDirection"
      :pagination-rounded="isPaginationRounded"
      default-sort="order_changed"
      aria-next-label="Siguiente"
      aria-previous-label="Anterior"
      aria-page-label="Página"
      aria-current-label="Actual"
      hoverable
      striped
      height="calc(100vh - 210px)"
    >
      <b-table-column field="order_id" label="ID" v-slot="props" width="50">
        {{ props.row.order_id }}
      </b-table-column>

      <b-table-column
        field="order_changed"
        :label="$t('Fecha')"
        v-slot="props"
        width="150"
      >
        {{ format_date(props.row.order_changed, 'hh:mm dd/MM/yyyy') }}
      </b-table-column>

      <b-table-column :label="$t('Nº prod.')" v-slot="props" width="50">
        {{ props.row.products.length }}
      </b-table-column>

      <b-table-column :label="$t('Total')" v-slot="props" width="100">
        {{ calculateTotal(props.row.products).toFixed(2) }} {{ $t('€') }}
      </b-table-column>

      <b-table-column field="order_status" :label="'Status'" v-slot="props">
        {{ props.row.order_status }}
      </b-table-column>

      <b-table-column
        field="membership_card.number"
        :label="$t('Nº de socio')"
        v-slot="props"
      >
        {{ props.row.membership_card.number }}
      </b-table-column>

      <b-table-column
        field="membership_card.nombre"
        :label="$t('Nombre')"
        v-slot="props"
      >
        {{ props.row.membership_card.nombre }}
      </b-table-column>

      <b-table-column
        field="membership_card.surname"
        :label="$t('Apellidos')"
        v-slot="props"
      >
        {{ props.row.membership_card.surname }}
      </b-table-column>

      <b-table-column
        field="membership_card.dni"
        :label="$t('DNI')"
        v-slot="props"
      >
        {{ props.row.membership_card.dni }}
      </b-table-column>

      <b-table-column
        field="order_signature"
        :label="$t('Firma')"
        v-slot="props"
      >
        <!-- {{ props.row.order_signature }} -->
        <a
          v-if="props.row.order_signature"
          :href="props.row.order_signature"
          target="_blank"
          >{{ $t('Ver') }}</a
        >
      </b-table-column>

      <!-- <b-table-column label="Acción" v-slot="props">
        <b-button class="is-small" @click="goToPOS(props.row)">Ver</b-button>
      </b-table-column> -->

      <template #detail="props">
        <b-table v-if="props.row.products" :data="props.row.products">
          <b-table-column field="code" :label="$t('SKU')" v-slot="props">
            {{ props.row.product.code }}
          </b-table-column>
          <b-table-column field="title" :label="$t('Producto')" v-slot="props">
            {{ props.row.product.title }}
          </b-table-column>

          <b-table-column
            field="category"
            :label="$t('Categoría')"
            v-slot="props"
          >
            {{ props.row.product.category }}
          </b-table-column>

          <b-table-column
            field="quantity"
            :label="$t('Cantidad')"
            v-slot="props"
          >
            {{ props.row.quantity }}
          </b-table-column>

          <b-table-column
            field="real_quantity"
            :label="$t('Peso real')"
            v-slot="props"
          >
            {{ props.row.real_quantity }}
          </b-table-column>

          <b-table-column field="total" :label="$t('Total')" v-slot="props">
            {{ props.row.total.toFixed(2) }}
          </b-table-column>

          <b-table-column
            field="refund_quantity"
            :label="$t('Cantidad Devol.')"
            v-slot="props"
            width="50"
          >
            <b-input
              v-if="props.row.quantity > 0"
              v-model="props.row.refund_quantity"
              @input="input(props.row)"
            ></b-input>
          </b-table-column>

          <b-table-column
            field="amount"
            :label="$t('Importe Devol.')"
            v-slot="props"
            width="50"
          >
            <b-input
              :placeholder="$t('€')"
              v-model="props.row.amount"
            ></b-input>
          </b-table-column>

          <template #footer>
            <div
              style="display:flex; justify-content:flex-end"
              v-if="props.row.order_status != 'cart'"
            >
              <span class="mr-2">{{ $t('Hacer devolución') }}</span>
              <b-button
                class="mr-2 is-small"
                @click="refundOrder('cash', props.row)"
                v-if="cashPermission"
                >{{ $t('Efectivo') }}</b-button
              >
              <b-button
                v-if="
                  (currentClub.functionalities.funciones_avanzadas_gestion ||
                    currentClub.functionalities.monedero) &&
                    walletPermission
                "
                class="mr-2 is-small"
                @click="refundOrder('wallet', props.row)"
                >{{ $t('Monedero') }}</b-button
              >
              <b-button
                class="mr-2 is-small"
                @click="refundOrder('tpv', props.row)"
                v-if="cardPermission"
                >{{ $t('Tarjeta') }}</b-button
              >
            </div>
          </template>
        </b-table>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { InitViewMixin } from '@/mixins/InitViewMixin.js'
import { ToolsMixin } from '@/mixins/ToolsMixin.js'
import tools from '@/Tools/tools.js'
import { ToastProgrammatic as Toast } from 'buefy'

export default {
  data() {
    return {
      json_fields: {},
      defaultOpenedDetails: [1],
      showDetailIcon: true,
      isPaginated: true,
      isPaginationSimple: false,
      isPaginationRounded: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'desc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      perPage: 19,
      page: 1,
      totalElements: 0,
      data: [],
      qtyTotal: 0,
      totalTotal: 0,
      start_date: new Date(new Date().setDate(new Date().getDate() - 7)),
      end_date: new Date(),
      locale: 'es-ES'
    }
  },
  mixins: [InitViewMixin, ToolsMixin],
  computed: {
    ...mapState('orders', ['orders', 'ordersData']),
    ...mapState('club', [
      'cashPermission',
      'cardPermission',
      'walletPermission'
    ])
  },
  methods: {
    onPageChange(page) {
      this.page = page
      // this.downloadData()
    },
    xlsHeader() {
      let fi =
        this.$t('Fecha inicial') + ': ' + this.datestring(this.start_date)
      let ff = this.$t('Fecha final') + ': ' + this.datestring(this.end_date)
      return [this.$t('INFORME DE PEDIDOS'), fi, ff]
    },
    csvHeader() {
      let fi = this.datestring(this.start_date)
      let ff = this.datestring(this.end_date)
      return this.$t('INFORME DE PEDIDOS') + '   ' + fi + ' / ' + ff
    },
    setupJsonFields() {
      this.json_fields['ID'] = 'order_id'

      this.json_fields[this.$t('Fecha')] = {
        field: 'order_changed',
        callback: value => {
          return this.format_date(value, 'hh:mm dd/MM/yyyy')
        }
      }
      this.json_fields[this.$t('Nº prod.')] = {
        field: 'products',
        callback: value => {
          return value.length
        }
      }
      this.json_fields[this.$t('Nº prod.')] = {
        field: 'products',
        callback: value => {
          return this.calculateTotal(value).toFixed(2)
        }
      }
      this.json_fields[this.$t('Nº Socio')] = 'membership_card.number'
      this.json_fields[this.$t('Nombre')] = 'membership_card.nombre'
      this.json_fields[this.$t('Apellidos')] = 'membership_card.surname'
      this.json_fields[this.$t('DNI')] = 'membership_card.dni'
      this.json_fields[this.$t('Firma')] = 'order_signature'
    },
    input(value) {
      // console.log('entra')
      // console.log(value)
      // value.amount =
      //   Number(value.refund_quantity) *
      //   (Number(value.total_base_price) / Number(value.quantity))
    },
    refundOrder(pay, order) {
      this.$buefy.dialog.confirm({
        title: this.$t('Confirmar devolución'),
        message: this.$t('¿Estás seguro de que deseas hacer la DEVOLUCIÓN?'),
        confirmText: this.$t('Devolver'),
        cancelText: this.$t('Cancelar'),
        type: 'is-danger',
        hasIcon: false,
        onConfirm: () => {
          const loadingComponent = this.$buefy.loading.open({
            container: null
          })
          let json = {
            asocid: this.currentClub.nid,
            oid: order.order_id,
            membership_id: order.membership_id,
            products: [],
            pay: pay
            // calculate: true
          }
          order.products.forEach(product => {
            if (
              product.refund_quantity &&
              product.refund_quantity != '' &&
              product.refund_quantity != '0'
            ) {
              let refundProdcut = {
                pid: product.product.pid,
                quantity: product.refund_quantity,
                total: product.amount
              }
              json.products.push(refundProdcut)
            }
          })
          this.$store
            .dispatch('orders/refundOrder', json)
            .then(response => {
              loadingComponent.close()
              Toast.open({
                duration: 4000,
                message: this.$t(`La devolución se ha realizado correctamente`),
                position: 'is-top',
                type: 'is-success'
              })
              this.end_date = new Date()
              this.downloadData(this.$route.query.ms_id)
            })
            .catch(error => {
              loadingComponent.close()
              console.log('Ha habido un error durante la devolución:' + error)
              tools.manageAxiosError(error)
            })
        }
      })
    },
    calculateTotal(products) {
      let total = 0
      products.forEach(product => {
        total = total + Number(product.total)
      })
      return total
    },
    downloadData(patient_id) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      console.log('patient_id')
      console.log(patient_id)
      this.data = []
      let json = {
        asocid: this.currentClub.nid,
        start_date: Date.parse(this.start_date) / 1000,
        end_date: Date.parse(this.end_date) / 1000
        // limit: this.perPage,
        // page: this.page
      }
      if (patient_id) {
        console.log('patient_id')
        console.log(patient_id)
        json.membership_id = patient_id
      }
      this.$store
        .dispatch('orders/fetchClubOrders', json)
        .then(() => {
          console.log(this.orders)
          this.totalElements = this.ordersData.total
          loadingComponent.close()
          this.setupJsonFields()

          // this.orders.forEach(order => {
          //   order.products.forEach(product => {
          //     product.order_id = order.order_id
          //     product.membership_card = order.membership_card
          //     product.order_changed = order.order_changed
          //     this.data.push(product)
          //     this.qtyTotal = this.qtyTotal + Number(product.quantity)
          //     this.totalTotal = this.totalTotal + product.total
          //   })
          // })
        })
        .catch(error => {
          loadingComponent.close()
        })
      // this.qtyTotal = this.qtyTotal.toFixed(2)
    }
  },
  mounted() {
    this.validateAccess(['software_gestion_club'])
    this.validateRoles(['Administrador', 'Manager', 'Budtender'])
    this.$analytics.logEvent('OrdersReport.vue')
    this.$store.dispatch('setShowNavigationBar', true)

    // this.downloadData(this.$route.query.ms_id)
  },
  watch: {
    '$route.params.ms_id': {
      handler: function(search) {
        console.log(search)
        // const loadingComponent = this.$buefy.loading.open({
        //   container: null
        // })
        this.downloadData(this.$route.query.ms_id)
      },
      deep: true,
      immediate: true
    },
    currentClub() {
      this.validateAccess(['software_gestion_club'])
      this.validateRoles(['Administrador', 'Manager', 'Budtender'])
      this.downloadData(this.$route.query.ms_id)
    },
    start_date() {
      this.downloadData(this.$route.query.ms_id)
    },
    end_date() {
      this.downloadData(this.$route.query.ms_id)
    }
  }
}
</script>

<style lang="scss" scoped>
.my-table {
  // max-height: 100%;
  overflow-y: auto;
  height: calc(100vh - 140px);
  // height: 1000px;
}
</style>
