<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ $t('Selecciona un idioma') }}:</p>
      <button type="button" class="delete ml-4" @click="$emit('close')" />
    </header>
    <section class="modal-card-body">
      <div style="display:flex; text-align: center;">
        <b-select
          :placeholder="$t('Idioma')"
          icon="globe-americas"
          v-model="selectedLanguage"
        >
          <option value="es">{{ $t('Español') }} </option>
          <option value="cat">{{ $t('Catalán') }}</option>
          <option value="it">{{ $t('Italiano') }}</option>
          <option value="pt">{{ $t('Portugués') }}</option>
          <option value="en">{{ $t('Inglés') }}</option>
          <option value="fr">{{ $t('Francés') }}</option>
        </b-select>
      </div>
    </section>
    <footer class="modal-card-foot">
      <!-- <b-button :label="$t('Cerrar')" @click="$emit('close')" /> -->
      <div style="flex-direction: row;">
        <b-button :label="$t('Guardar')" type="is-primary" @click="save()" />
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedLanguage: undefined
    }
  },
  methods: {
    save() {
      localStorage.setItem(
        'language',
        JSON.stringify({ language: this.selectedLanguage })
      )
      this.$i18n.locale = this.selectedLanguage
      this.$emit('close')
    }
  },
  mounted() {
    const lang = JSON.parse(localStorage.getItem('language'))
    if (lang && lang.language) {
      this.selectedLanguage = lang.language
    } else {
      this.selectedLanguage = 'es'
    }
    this.selectedLanguage
  }
}
</script>

<style lang="scss" scoped></style>
