<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ $t('Cupón') }}</p>
      <button type="button" class="delete" @click="$emit('close')" />
    </header>
    <section class="modal-card-body">
      <b-field>
        <b-select
          :placeholder="$t('Seleccionar cupón')"
          icon="cannabis"
          v-model="selectedCupon"
        >
          <option v-for="(cupon, i) in cupons" :key="i" :value="cupon">
            {{ cupon.title }}
          </option>
        </b-select>
        <b-input v-model="code" :placeholder="$t('Código')"> </b-input>
        <b-button
          :label="$t('Aplicar cupón')"
          type="is-primary"
          @click="consumeCupon()"
        />
        <b-button
          @click="qrtoggle()"
          style="margin-top:5px; margin-left:3px; width:30px;  height:30px; background:#ffffff; color:#000000"
          ><i class="fas fa-qrcode fa"></i>
        </b-button>
      </b-field>
      <div
        v-if="showQRReader"
        style="display: flex; align-items: center;justify-content: center;"
      >
        <QRReader @qrResult="qrResult"> </QRReader>
      </div>
    </section>
    <footer class="modal-card-foot">
      <!-- <b-button :label="$t('Cerrar')" @click="$emit('close')" /> -->
      <b-field :label="$t('Descuento manual')">
        <!-- <b-select :placeholder="$t('Tipo')" v-model="type">
          <option value="€">{{ $t('€') }}</option>
          <option value="%">%</option>
        </b-select> -->
        <b-input
          v-model="quantity"
          :placeholder="$t('Cantidad')"
          @keyup.native.enter="manualDiscount(quantity)"
        >
        </b-input>

        <!-- <b-select :placeholder="$t('Línea de pedido')" v-model="lineProduct">
          <option value="all">{{ $t('Todo el pedido') }}</option>
          <option v-for="(product, i) in order.products" :key="i">
            {{ product.product.title }}
          </option>
        </b-select> -->
        <b-button
          v-if="is_editing"
          :label="$t('Modificar')"
          type="is-primary"
          @click="updateData()"
        />
        <b-button
          v-else
          :label="$t('Aplicar descuento')"
          type="is-primary"
          @click="manualDiscount(quantity)"
        />
      </b-field>
    </footer>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import session from '@/services/Session.js'
import { DialogProgrammatic as Dialog } from 'buefy'
import QRReader from '@/components/QRReader.vue'

export default {
  props: { order: Object, currentPatient: Object },
  data() {
    return {
      is_editing: false,
      code: undefined,
      quantity: undefined,
      categoryName: null,
      selectedCupon: undefined,
      lineProduct: undefined,
      type: undefined,
      cupons: [],
      showQRReader: false
    }
  },
  computed: {
    ...mapState('club', ['currentClub']),
    ...mapState('orders', ['orders'])
  },
  components: {
    QRReader
  },
  methods: {
    qrtoggle() {
      if (!this.showQRReader) {
        //this.qrButtonTitle = 'Cerrar QR'
      } else {
        //this.qrButtonTitle = 'Validar mediante QR'
      }
      this.showQRReader = !this.showQRReader
      this.$matomo.trackEvent('CuponConsume', 'Click', 'qrReader', 1)
    },
    qrResult(result) {
      console.log('este es el resultado')
      console.log(result)
      this.code = result
      this.showQRReader = !this.showQRReader
      this.consumeCupon()
    },
    downloadData() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      session
        .get('/api/smokapp_coupon?asocid=' + this.currentClub.nid)
        .then(response => {
          this.cupons = response.data
          loadingComponent.close()
        })
    },
    consumeCupon() {
      if (
        this.order.manual_discount_order &&
        this.order.manual_discount_order > 0
      ) {
        Dialog.alert({
          title: this.$t('Advertencia'),
          message: this.$t(
            'No se puede aplicar un descuento por cupón a un pedido que ya tiene aplicado un descuento manual'
          ),
          confirmText: 'OK',
          type: 'is-danger',
          hasIcon: false,
          onConfirm: () => {}
        })
        return
      }
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      var json = {
        asocid: this.currentClub.nid,
        oid: this.order.order_id
      }
      if (this.code) {
        json.code = this.code
      } else {
        json.coupon_id = [Number(this.selectedCupon.id)]
      }

      session
        .post('/api/smokapp_coupon/apply_coupon', json)
        .then(response => {
          this.cupons = response.data
          loadingComponent.close()
          this.fetchOrders()
          this.$emit('close')
        })
        .catch(e => {
          loadingComponent.close()

          Dialog.alert({
            title: this.$t('Advertencia'),
            message: e.response.data.message,
            confirmText: 'OK',
            type: 'is-danger',
            hasIcon: false,
            onConfirm: () => {}
          })
        })
    },

    isNumeric(str) {
      //refactorizado con regex expresion regular
      return /^-?[\d.,]+(?:e-?\d+)?$/.test(str)
    },
    manualDiscount(manualDiscount) {
      if (!this.isNumeric(manualDiscount)) {
        this.$buefy.snackbar.open({
          duration: 2000,
          message: 'Introduce valores numéricos',
          type: 'is-primary',
          position: 'is-top',
          actionText: 'Ok',
          queue: false
        })
        return
      }
      if (
        this.order.discount_coupon_order &&
        this.order.discount_coupon_order.length > 0
      ) {
        Dialog.alert({
          title: this.$t('Advertencia'),
          message: this.$t(
            'No se puede aplicar un descuento manual a un pedido que ya tiene aplicado un descuento por cupón'
          ),
          confirmText: 'OK',
          type: 'is-danger',
          hasIcon: false,
          onConfirm: () => {}
        })
        return
      }
      if (manualDiscount < 0) {
        Dialog.alert({
          title: this.$t('Advertencia'),
          message: this.$t('No se puede aplicar un descuento negativo'),
          confirmText: 'OK',
          type: 'is-danger',
          hasIcon: false,
          onConfirm: () => {}
        })
        return
      }
      if (manualDiscount > this.order.order_base_price) {
        Dialog.alert({
          title: this.$t('Advertencia'),
          message: this.$t(
            'No se puede aplicar un descuento mayor al total del pedido'
          ),
          confirmText: 'OK',
          type: 'is-danger',
          hasIcon: false,
          onConfirm: () => {}
        })
        return
      }

      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      var updatedOrder = {
        membership_id: this.currentPatient.nid,
        asocid: this.currentClub.nid,
        oid: this.order.order_id,
        discount_coupon_order: this.order.discount_coupon_order,
        manual_discount_order: String(manualDiscount).replace(',', '.')
      }
      var products = []
      // console.log(this.currentOrder)
      this.order.products.forEach(product => {
        products.push({
          pid: product.product.pid,
          quantity: product.quantity,
          real_quantity: product.real_quantity,
          total: product.total_base_price
        })
      })

      updatedOrder.products = products

      this.$store.dispatch('orders/postOrder', updatedOrder).then(() => {
        loadingComponent.close()
        this.$emit('close')
      })
    },
    fetchOrders() {
      if (this.currentClub && this.currentPatient) {
        this.$store.dispatch('orders/fetchPatientOrders', {
          asocid: this.currentClub.nid,
          fichaid: this.currentPatient.nid
        })
      }
    }
  },
  mounted() {
    this.downloadData()
  }
}
</script>

<style lang="scss" scoped></style>
